<template>
  <div class="main">
    <div class="content">
      <!-- 头 -->
      <div class="top" :style="{backgroundColor:isSkin}">
        <div class="thumb-title">图书高级搜索</div>
      </div>
      <!-- 搜索框 -->
      <div class="search-one">
          <div class="book-title1">图书名称</div>
          <input class="input-title" type="text" v-model="name" placeholder="请输入图书名称">
          <div class="book-title2" v-html="'作&emsp;&emsp;者'"></div>
          <input class="input-title" type="text" v-model="author" placeholder="请输入作者">
          <div class="book-title3">I S B N</div>
          <input class="input-title" type="text" v-model="bookNumber" placeholder="请输入ISBN">
      </div>
      <div class="search-two">
          <div class="book-title1">发 行 号</div>
          <input class="input-title" type="text" v-model="releaseNumber" placeholder="请输入发行号">
          <div class="book-title2" v-html="'责&emsp;&emsp;编'"></div>
          <input class="input-title" type="text" v-model="compile" placeholder="请输入责编">
        <div class="btn-view">
          <div class="sousuo" :style="{backgroundColor:isSkin}" @click.stop="searchMethod()">搜索</div>
          <div class="cz" @click.stop="resetMethod()">重置</div>
        </div>
      </div>
      <!-- 关键字 -->
      <div class="keyword">{{'搜索到'+totalNumber+'个结果'}}</div>
      <!-- 内容列表 -->
      <div class="list">
        <div class="list-view">
          <bookList v-for="(item,index) in List" :key="index" :index="index" :item="item"></bookList>
        </div>
        <div class="page" v-show="pageShow">
            <div class="page1">
                <el-pagination background :current-page="currentPage"
                  :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bookList from './components/bookSearch-box'
export default {
    components:{bookList},
    data() {
      return {
        pageShow:false,
        totalNumber:0,
        totals:1,
        currentPage:1,
        pageSize:10,
        title:'',
        List:[],
        author:'',
        bookNumber:'',
        compile:'',
        name:'',
        releaseNumber:''
      }
    },
    created(){
        this.title = this.$route.query.title
        this.$store.commit('changClick',1)
        var skin = sessionStorage.getItem('Skin')
		    var zskin = sessionStorage.getItem('ZSkin')
        if (skin) {
            this.$store.commit('changSkin',skin)
        }
		    if (zskin) {
            this.$store.commit('changZSkin',zskin)
        }
		    
        this.handleCurrentChange(1)
        
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },   
    },
    methods:{
      /**搜索按钮**/
      searchMethod(){
        this.getBookSearch()
      },
      /**重置按钮**/
      resetMethod(){
        this.author = ''
        this.bookNumber = ''
        this.compile = ''
        this.name = ''
        this.releaseNumber = ''
      },
      getBookSearch(){
        let that = this
        that.$api.getBookSearch({
          params:{
            type:2,
            author:that.author,
            bookNumber:that.bookNumber,
            compile:that.compile,
            keyword:'',
            name:that.name,
            pageNum:that.currentPage,
            pageSize:that.pageSize,
            releaseNumber:that.releaseNumber
          }
        }).then(res=>{
          if (res.data.code == 0) {
            that.List = res.data.data.list
            that.totals = res.data.data.pages
            that.totalNumber = res.data.data.total
            if (res.data.data.list.length == 0){
              that.pageShow = false
            }else {
              that.pageShow = true
            }
          }else{
            that.List = []
            that.totalNumber = 0
            that.pageShow = false
          }
        })
      },
        /**当前页按钮**/ 
        handleCurrentChange(index) {
            console.log(index);
            this.currentPage = index;
            this.getBookSearch()
            this.$nextTick(function () {
                /**更换分页按钮皮肤**/
                var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
                if(array && array.length>0){
                array.forEach((item,index1) => {
                    if (index == array[index1].innerText) {
                    array[index1].style.background  = this.isSkin;
                    }else{
                    array[index1].style.background  = "#f4f4f5";
                    }
                })
                }
            });
        },
    }
}
</script>

<style scoped lang="scss">
.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content{
        width: 1200px;
        .top{
            width: 100%;
            height: 55px;
            border-radius: 4px;
            line-height: 55px;
            margin-top: 50px;
            display: flex;
            justify-items: center;
            align-items: center;
            .thumb-title{
                font-size: 18px;
                color: #FFFFFF;
                margin-left: 12px;
            }
            .gaoji{
                font-size: 13px;
                line-height: 18px;  
                color: #FFFFFF;
                cursor: pointer;
                margin-left: 20px;
            }
        }
        .search-one{
            display: flex;
            justify-items: center;
            align-items: center;
            height: 40px;
            margin-top: 40px;
            width: 100%;
            .book-title1{
                font-size: 14px;
                line-height: 20px;
                color: #666666;
                padding-right: 10px;
            }
            .book-title2{
                font-size: 14px;
                line-height: 20px;
                color: #666666;
                padding-right: 10px;
                margin-left: 37px;
            }
            .book-title3{
                font-size: 14px;
                line-height: 20px;
                color: #666666;
                padding-right: 10px;
                margin-left: 37px;
            }
        }
        .search-two{
            display: flex;
            justify-items: center;
            align-items: center;
            height: 40px;
            margin-top: 40px;
            width: 100%;
            .book-title1{
                font-size: 14px;
                line-height: 20px;
                color: #666666;
                padding-right: 15px;
            }
            .book-title2{
                font-size: 14px;
                line-height: 20px;
                color: #666666;
                padding-right: 10px;
                margin-left: 37px;
            }
        }
        .btn-view{
            margin-left: 90px;
            height: 42px;
            display: flex;
            justify-items: center;
            align-items: center;
            .sousuo{
                width: 80px;
                height: 42px;
                border-radius: 4px;
                font-size: 16px;
                line-height: 42px;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }
            .cz{
                width: 80px;
                height: 42px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 16px;
                line-height: 42px;
                color: #666;
                text-align: center;
                margin-left: 50px;
                cursor: pointer;
            }
        }
        .keyword{
            font-size: 14px;
            line-height: 20px;
            color: #666666;
            padding: 40px;
        }
        .list{
            width: 100%;
            .list-view{
                display: flex;
                display: -webkit-flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                width: 100%;
            }
            .page{
                width: 1200px;
                padding: 20px 20px 100px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .page1{
                display: flex;
                justify-items: center;
                align-items: center;
                }
            }
        }
    }
}
.input-title{
    width: 311px;
    height: 40px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none; //去点击蓝色边框
    padding-left: 10px;
    padding-right: 30px;
    font-size: 15px;
    line-height: 40px;
    color: #666666;
}
</style>